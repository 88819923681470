<template>
  <div class="health">
    <div class="health-left">
      <div class="health-top">
        <div class="health-title">
          <i class="el-icon-s-order"></i>
          <span>淅川侧下游1号除湿机<span style="color:#000;padding-left: 20px;font-weight: normal">{{ dataList[0].time }}</span></span>
        </div>
        <div class="health-img">
          <div class="health-hum">{{ dataList[0].humidity.toFixed(1) }}</div>
          <div class="health-tem">{{ dataList[0].temper.toFixed(1) }}</div>
          <div class="health-air-tem">{{ dataList[0].airTemperature.toFixed(1) }}</div>
          <div class="health-react-tem">{{ dataList[0].reactTemper.toFixed(1) }}</div>
          <div class="health-status-mode">{{ dictMode[dataList[0].mode] + ": " + dictStatus[dataList[0].status]}}</div>
          <div class="health-set-hum">设定值: <div class="health-inner-num">{{ dataList[0].humSet.toFixed(1) }}</div>%RH</div>
          <div class="health-hum-one">湿度1: <div class="health-inner-num">{{ dataList[0].hum1.toFixed(1) }}</div>%RH</div>
          <div class="health-hum-two">湿度2: <div class="health-inner-num">{{ dataList[0].hum2.toFixed(1) }}</div>%RH</div>
          <div class="health-tem-one">温度1: <div class="health-inner-num">{{ dataList[0].tem1.toFixed(1) }}</div>℃</div>
          <div class="health-tem-two">温度2: <div class="health-inner-num">{{ dataList[0].tem2.toFixed(1) }}</div>℃</div>
          <div class="health-run-time">运行时间: <div class="health-inner-num">{{ dataList[0].runTime.toFixed(0) }}</div>小时</div>
          <img :src="componentImg" alt="">
        </div>
      </div>
      <div class="health-bot">
        <div class="health-title">
          <i class="el-icon-s-order"></i>
          <span>十堰侧下游3号除湿机<span style="color:#000;padding-left: 20px;font-weight: normal">{{ dataList[2].time }}</span></span>
        </div>
        <div class="health-img">
          <div class="health-hum">{{ dataList[2].humidity.toFixed(1) }}</div>
          <div class="health-tem">{{ dataList[2].temper.toFixed(1) }}</div>
          <div class="health-air-tem">{{ dataList[2].airTemperature.toFixed(1) }}</div>
          <div class="health-react-tem">{{ dataList[2].reactTemper.toFixed(1) }}</div>
          <div class="health-status-mode">{{ dictMode[dataList[2].mode] + ": " + dictStatus[dataList[2].status]}}</div>
          <div class="health-set-hum">设定值: <div class="health-inner-num">{{ dataList[2].humSet.toFixed(1) }}</div>%RH</div>
          <div class="health-hum-one">湿度1: <div class="health-inner-num">{{ dataList[2].hum1.toFixed(1) }}</div>%RH</div>
          <div class="health-hum-two">湿度2: <div class="health-inner-num">{{ dataList[2].hum2.toFixed(1) }}</div>%RH</div>
          <div class="health-tem-one">温度1: <div class="health-inner-num">{{ dataList[2].tem1.toFixed(1) }}</div>℃</div>
          <div class="health-tem-two">温度2: <div class="health-inner-num">{{ dataList[2].tem2.toFixed(1) }}</div>℃</div>
          <div class="health-run-time">运行时间: <div class="health-inner-num">{{ dataList[2].runTime.toFixed(0) }}</div>小时</div>
          <img :src="componentImg" alt="">
        </div>
      </div>
    </div>
    <div class="health-right">
      <div class="health-top">
        <div class="health-title">
          <i class="el-icon-s-order"></i>
          <span>淅川侧上游2号除湿机<span style="color:#000;padding-left: 20px;font-weight: normal">{{ dataList[1].time }}</span></span>
        </div>
        <div class="health-img">
          <div class="health-hum">{{ dataList[1].humidity.toFixed(1) }}</div>
          <div class="health-tem">{{ dataList[1].temper.toFixed(1) }}</div>
          <div class="health-air-tem">{{ dataList[1].airTemperature.toFixed(1) }}</div>
          <div class="health-react-tem">{{ dataList[1].reactTemper.toFixed(1) }}</div>
          <div class="health-status-mode">{{ dictMode[dataList[1].mode] + ": " + dictStatus[dataList[1].status]}}</div>
          <div class="health-set-hum">设定值: <div class="health-inner-num">{{ dataList[1].humSet.toFixed(1) }}</div>%RH</div>
          <div class="health-hum-one">湿度1: <div class="health-inner-num">{{ dataList[1].hum1.toFixed(1) }}</div>%RH</div>
          <div class="health-hum-two">湿度2: <div class="health-inner-num">{{ dataList[1].hum2.toFixed(1) }}</div>%RH</div>
          <div class="health-tem-one">温度1: <div class="health-inner-num">{{ dataList[1].tem1.toFixed(1) }}</div>℃</div>
          <div class="health-tem-two">温度2: <div class="health-inner-num">{{ dataList[1].tem2.toFixed(1) }}</div>℃</div>
          <div class="health-run-time">运行时间: <div class="health-inner-num">{{ dataList[1].runTime.toFixed(0) }}</div>小时</div>
          <img :src="componentImg" alt="">
        </div>
      </div>
      <div class="health-bot">
        <div class="health-title">
          <i class="el-icon-s-order"></i>
          <span>十堰侧上游4号除湿机<span style="color:#000;padding-left: 20px;font-weight: normal">{{ dataList[3].time }}</span></span>
        </div>
        <div class="health-img">
          <div class="health-hum">{{ dataList[3].humidity.toFixed(1) }}</div>
          <div class="health-tem">{{ dataList[3].temper.toFixed(1) }}</div>
          <div class="health-air-tem">{{ dataList[3].airTemperature.toFixed(1) }}</div>
          <div class="health-react-tem">{{ dataList[3].reactTemper.toFixed(1) }}</div>
          <div class="health-status-mode">{{ dictMode[dataList[3].mode] + ": " + dictStatus[dataList[3].status]}}</div>
          <div class="health-set-hum">设定值: <div class="health-inner-num">{{ dataList[3].humSet.toFixed(1) }}</div>%RH</div>
          <div class="health-hum-one">湿度1: <div class="health-inner-num">{{ dataList[3].hum1.toFixed(1) }}</div>%RH</div>
          <div class="health-hum-two">湿度2: <div class="health-inner-num">{{ dataList[3].hum2.toFixed(1) }}</div>%RH</div>
          <div class="health-tem-one">温度1: <div class="health-inner-num">{{ dataList[3].tem1.toFixed(1) }}</div>℃</div>
          <div class="health-tem-two">温度2: <div class="health-inner-num">{{ dataList[3].tem2.toFixed(1) }}</div>℃</div>
          <div class="health-run-time">运行时间: <div class="health-inner-num">{{ dataList[3].runTime.toFixed(0) }}</div>小时</div>
          <img :src="componentImg" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  components:{},
  data(){
    return{
      componentImg: require('@/assets/images/single/dehumidification.png'),
      timer: null,
      dictStatus: {
        running: "运行"
      },
      dictMode: {
        auto: "自动"
      },
      dataList: [
        {
          humidity: 0,
          temper: 0,
          humSet: 0,
          airTemperature: 0,
          reactTemper: 0,
          hum1: 0,
          hum2: 0,
          tem1: 0,
          tem2: 0,
          runTime: 0,
          status: "running",
          mode: "auto"
        },
        {
          humidity: 0,
          temper: 0,
          humSet: 0,
          airTemperature: 0,
          reactTemper: 0,
          hum1: 0,
          hum2: 0,
          tem1: 0,
          tem2: 0,
          runTime: 0,
          status: "running",
          mode: "auto"
        },
        {
          humidity: 0,
          temper: 0,
          humSet: 0,
          airTemperature: 0,
          reactTemper: 0,
          hum1: 0,
          hum2: 0,
          tem1: 0,
          tem2: 0,
          runTime: 0,
          status: "running",
          mode: "auto"
        },
        {
          humidity: 0,
          temper: 0,
          humSet: 0,
          airTemperature: 0,
          reactTemper: 0,
          hum1: 0,
          hum2: 0,
          tem1: 0,
          tem2: 0,
          runTime: 0,
          status: "running",
          mode: "auto"
        }
      ]
    }
  },
  created() {
    this.timer = setInterval(this.getData, 2000);
  },
  methods:{
    getData() {
      this.$http.get('/dehumidification/dehumidificationData').then(res => {
        if (res.success){
          if (res.data){
            this.dataList = res.data;
          }
        } else {
          this.$message.error(res.mag)
        }
      })
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null
  }
}
</script>

<style scoped>
.health-title{
  width: 100%;
  height: 50px;
  line-height: 50px;
  display: flex;
  align-content: center;
  align-items: center;
  font-size: 16px;
  /*border-bottom: 1px solid #efefef;*/
  margin-bottom: 10px;
}
.health-title i{
  color: #1A67D0;
  font-size: 20px;
  margin-right: 5px;
}
.health-title span{
  color: #1A67D0;
  font-weight: bold;
}
.health{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.health-left{
  width: 49.5%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}
.health-top, .health-bot{
  width: 100%;
  height: 49%;
  background: #fff;
  padding: 10px 20px;
}
.health-img{
  width: 450px;
  height: 300px;
  margin : 10px auto 0;
  display: flex;
  justify-content: center;
  position: relative;
}
.health-img img{
  width: 100%;
  height: 100%;
}
.health-img span{
  display: block;
  width: 100%;
  text-align: center;
  position: absolute;
  left: 0;
  top: 65px;
  font-size: 22px;
  font-weight: bold;
  color: #4DE1B4;
}
.health-right{
  width: 49.5%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}
.health-hum {
  color: #2C74E1;
  position: absolute;
  font-weight: bold;
  font-size: 40px;
  left: 18px;
  top: 55px;
}
.health-tem {
  color: #2C74E1;
  position: absolute;
  font-weight: bold;
  font-size: 40px;
  left: 18px;
  top: 110px;
}
.health-react-tem {
  color: #2C74E1;
  position: absolute;
  font-weight: bold;
  font-size: 16px;
  left: 370px;
  top: 136px;
}
.health-air-tem {
  color: #2C74E1;
  position: absolute;
  font-weight: bold;
  font-size: 16px;
  left: 370px;
  top: 60px;
}
.health-status-mode {
  color: lightgreen;
  position: absolute;
  font-size: 14px;
  left: 0;
  top: 255px;
}
.health-time {
  color: #FFFFFF;
  position: absolute;
  font-weight: bold;
  font-size: 20px;
  left: 220px;
  top: 262px;
}
.health-run-time {
  color: #FFFFFF;
  position: absolute;
  font-size: 14px;
  left: 160px;
  top: 255px;
}
.health-tem-one {
  color: #FFFFFF;
  position: absolute;
  font-size: 14px;
  left: 0;
  top: 280px;
}
.health-tem-two {
  color: #FFFFFF;
  position: absolute;
  font-size: 14px;
  left: 110px;
  top: 280px;
}
.health-hum-one {
  color: #FFFFFF;
  position: absolute;
  font-size: 14px;
  left: 220px;
  top: 280px;
}
.health-hum-two {
  color: #FFFFFF;
  position: absolute;
  font-size: 14px;
  left: 345px;
  top: 280px;
}
.health-set-hum {
  color: #FFFFFF;
  position: absolute;
  font-size: 14px;
  left: 340px;
  top: 255px;
}
.health-inner-num {
  display: inline-block;
  color: lightgreen;
}
</style>
